<template>
  <div>
    <div class="flex flex-row justify-between items-center mb-2">
      <div class="flex justify-between w-64">
        <span>Media</span>
      </div>
    </div>

    <div class="flex-col overflow-x-auto overflow-y-hidden cursor-drag mb-4">
      <div class="max-w-full">
        <div class="flex flex-row flex-wrap gap-4 flex-wrap">
          <template
            v-for="(media, i) in medias.filter(
              (m) =>
                m.type !== mediaTypes.cover && m.type !== mediaTypes.executable
            )"
          >
            <button @click="removeMedia(media)" class="item" :key="media.id">
              <img
                v-if="media.type === mediaTypes.image"
                :id="media.type + '-image-' + i"
                :src="getImage(media, i)"
                class="absolute filter brightness-50 blur-1 w-full h-full z-0 top-0 right-0 bg-cover bg-center bg-no-repeat"
              />
              <div
                v-else
                class="absolute filter brightness-50 blur-1 w-full h-full z-0 top-0 right-0 bg-cover bg-center bg-no-repeat"
                :style="{
                  backgroundImage: `url(${getThumbnail(media)})`,
                }"
              ></div>
              <i class="z-1 bx text-2xl bx-x"></i>
            </button>
          </template>

          <div @click="openModal()" class="add-item">
            <i class="bx text-2xl bx-plus"></i>
          </div>
        </div>
      </div>

      <div class="max-w-full mt-4">
        <span>Cover</span>
        <div class="mt-2 flex flex-row flex-wrap gap-4 flex-wrap">
          <template
            v-for="(media, i) in medias.filter(
              (m) => m.type === mediaTypes.cover
            )"
          >
            <button @click="removeMedia(media)" class="item" :key="media.id">
              <img
                :id="media.type + '-image-' + i"
                :src="getImage(media, i)"
                class="absolute filter brightness-50 blur-1 w-full h-full z-0 top-0 right-0 bg-cover bg-center bg-no-repeat"
              />
              <i class="z-1 bx text-2xl bx-x"></i>
            </button>
          </template>

          <div
            v-if="!medias.filter((m) => m.type === mediaTypes.cover).length"
            @click="selectFile('cover')"
            class="add-item"
          >
            <i class="bx text-2xl bx-plus"></i>
          </div>
        </div>
      </div>
    </div>

    <AddMedia ref="addMedia" />

    <input @change="addMedia" id="cover" type="file" class="hidden" />
  </div>
</template>

<script>
import AddMedia from "@/views/seller/products/create-product/AddMedia";
import { getImagesByProduct } from "@/services/ApiService";

export default {
  name: "Medias",
  components: { AddMedia },
  data() {
    return {
      oldImages: [],
    };
  },
  methods: {
    removeMedia(media) {
      const file = this.oldImages.find(
        (m) =>
          media.type !== this.mediaTypes.video && m.name === media.file.name
      );
      if (file) {
        this.$store.state.deletedMedias.push(file.id);
      }
      this.$store.state.medias.splice(
        this.$store.state.medias.indexOf(media),
        1
      );
    },
    selectFile(id) {
      const input = document.getElementById(id);
      if (input) {
        input.click();
      }
    },
    addMedia(e) {
      this.$store.state.videoActive = false;
      const type = e.target.id;
      const file = e.target.files[0];
      const obj = this.mediaTypes;

      this.$store.state.medias.push({ file, type: obj[type] });
    },
    async getImage(media, i) {
      await this.$nextTick();

      const img = document.getElementById(`${media.type}-image-${i}`);
      if (img) {
        img.src = URL.createObjectURL(media.file);
      }
    },
    async getFileFromUrl(url, name, defaultType = "image/jpeg") {
      const response = await fetch(url);
      const blob = await response.blob();
      return new File([blob], name, {
        type: defaultType,
      });
    },
    getThumbnail(media) {
      const id = this.getIdFromUrl(media.url);
      return `https://img.youtube.com/vi/${id}/0.jpg`;
    },
    getIdFromUrl(url) {
      const id = url.match(
        /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|&v(?:i)?=))([^#&?]*).*/
      );
      return id ? id[1] : null;
    },
    openModal() {
      this.$refs.addMedia.active = true;
    },
  },
  computed: {
    medias() {
      return this.$store.state.medias || [];
    },
    mediaTypes() {
      return this.$store.state.mediaTypes || {};
    },
  },
  async created() {
    const id = this.$route.params.id;

    if (id) {
      this.$store.state.medias = [];
      this.$store.state.deletedMedias = [];
      const images = await getImagesByProduct(id);
      for (const image of images) {
        image.url = this.$store.state.cdnLink + "/" + image.name;
        const file = await this.getFileFromUrl(
          image.url,
          image.name,
          image.mimeType
        );

        this.oldImages.push(image);
        this.$store.state.medias.push({
          id: image.id,
          file,
          type:
            image.type === this.mediaTypes.image
              ? this.mediaTypes.image
              : this.mediaTypes.cover,
        });
      }
    }
  },
};
</script>

<style scoped>
.item:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 32, 35, var(--tw-bg-opacity));
  --tw-shadow: 0px 5px 25px -4px rgba(0, 0, 0, .3);
  --tw-shadow-colored: 0px 5px 25px -4px var(--tw-shadow-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
}
.item {
  --tw-bg-opacity: 1;
  background-color: rgba(20, 20, 23, var(--tw-bg-opacity));
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0.75rem;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  height: 6.75rem;
  overflow: hidden;
  position: relative;
  width: 12rem;
  -webkit-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
.add-item {
  --tw-bg-opacity: 1;
  background-color: rgba(20, 20, 23, var(--tw-bg-opacity));
  border-radius: 0.75rem;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  height: 6.75rem;
  width: 12rem;
  -webkit-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
.add-item:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 32, 35, var(--tw-bg-opacity));
  --tw-shadow: 0px 5px 25px -4px rgba(0, 0, 0, .3);
  --tw-shadow-colored: 0px 5px 25px -4px var(--tw-shadow-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
}
</style>
