<template>
  <div>
    <div
      class="flex w-full items-center gap-x-4 h-full bg-[#141417] rounded-t-xl p-2 px-3"
    >
      <i
        class="bx bx-bold !text-xl cursor-pointer"
        @click="editor.chain().focus().toggleBold().run()"
        :class="{ 'is-active': editor && editor.isActive('bold') }"
      ></i>
      <i
        class="bx bx-italic !text-xl cursor-pointer"
        @click="editor.chain().focus().toggleItalic().run()"
        :class="{ 'is-active': editor && editor.isActive('italic') }"
      ></i>
      <i
        class="bx bx-list-ul !text-2xl cursor-pointer"
        @click="editor.chain().focus().toggleBulletList().run()"
        :class="{ 'is-active': editor && editor.isActive('bulletList') }"
      ></i>
      <i
        class="bx bx-list-ol !text-2xl cursor-pointer"
        @click="editor.chain().focus().toggleOrderedList().run()"
        :class="{ 'is-active': editor && editor.isActive('orderedList') }"
      ></i>
    </div>
    <div
      class="bg-[#141417] rounded-b-xl p-2 px-4 pt-4 h-34 overflow-auto mt-2"
    >
      <editor-content :editor="editor" />
    </div>
  </div>
</template>

<script>
import StarterKit from "@tiptap/starter-kit";
import { Editor, EditorContent } from "@tiptap/vue-2";

export default {
  props: {
    value: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      editor: null,
      emitAfterOnUpdate: false,
    };
  },
  components: {
    EditorContent,
  },
  async mounted() {
    this.editor = new Editor({
      extensions: [StarterKit],
      content: this.value,
    });

    this.editor.on("update", ({ editor }) => {
      this.emitAfterOnUpdate = true;
      this.$store.state.productDescription = editor.getHTML();
    });
  },
  beforeDestroy() {
    if (this.editor) this.editor.destroy();
  },
  watch: {
    value(val) {
      if (this.emitAfterOnUpdate) {
        this.emitAfterOnUpdate = false;
        return;
      }
      if (this.editor) this.editor.commands.setContent(val, false);
    },
  },
};
</script>
