<template>
  <div>
    <input id="exe-file" type="file" @change="setExeFile" class="hidden" />

    <div class="flex">
      <vs-button icon @click="open()">
        <i class="bx bx-upload"></i>
      </vs-button>

      <vs-input
        id="exe-file-value"
        value="Select Exe File"
        disabled="true"
        block
      ></vs-input>
    </div>
  </div>
</template>

<script>
export default {
  name: "AddExeFile",
  methods: {
    open() {
      const element = document.getElementById("exe-file");
      if (element) {
        element.click();
      }
    },
    setExeFile(e) {
      const file = e.target.files[0];
      const exeControl = this.exeFileFilter(file && file.name);

      if (exeControl) {
        const index = this.$store.state.medias.findIndex((media) => {
          return media.type === this.mediaTypes.executable;
        });

        if (index > -1) {
          this.$store.state.medias.splice(index, 1);
        }

        this.$store.state.medias.push({
          type: this.mediaTypes.executable,
          file,
        });

        const element = document.getElementById("vs-input--exe-file-value");
        if (element) {
          element.value = file.name;
        }
        return;
      }

      this.$vs.notification({
        position: "bottom-right",
        name: "Error",
        color: "danger",
        text: "Only .exe files are allowed",
      });
    },
    exeFileFilter(name) {
      return name.match(/\.(exe)$/);
    },
  },
  computed: {
    mediaTypes() {
      return this.$store.state.mediaTypes;
    },
  },
};
</script>

<style scoped>

</style>
