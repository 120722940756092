<template>
  <div class="center">
    <vs-dialog v-model="active">
      <template #header>
        <h4>Add media</h4>
      </template>
      <div class="flex flex-col gap-4">
        <vs-input
          v-if="selectedType && selectedType === 'video'"
          placeholder="Url"
          v-model="url"
          block
        >
          <template v-if="url && !validURl" #message-danger>
            Url needs to be a valid url
          </template>
        </vs-input>

        <div v-if="selectedType && selectedType === 'image'" class="flex">
          <vs-button icon @click="openFileModal">
            <i class="bx bx-upload"></i>
          </vs-button>

          <vs-input
            id="media-file-input"
            value="Select File"
            block
            disabled="true"
          ></vs-input>
        </div>

        <vs-select placeholder="Media Type" v-model="selectedType" block>
          <vs-option
            v-for="type of types"
            :key="type"
            :label="type"
            :value="type"
          >
            {{ type }}
          </vs-option>
        </vs-select>
        <div>
          <vs-select
            v-if="selectedType === 'video'"
            placeholder="Media Source"
            v-model="selectedMediaSource"
            block
          >
            <vs-option
              v-for="mediaSource of mediaSources"
              :key="mediaSource"
              :label="mediaSource"
              :value="mediaSource"
            >
              {{ mediaSource }}
            </vs-option>
          </vs-select>
        </div>

        <template v-if="selectedType && selectedType === 'video' && validURl">
          <span class="text-xl font-bold filter brightness-60">Preview</span>
          <img :src="getThumbnail" alt="preview" />
        </template>
      </div>
      <template #footer>
        <div class="footer-dialog">
          <vs-button @click="addMedia()" block> Add media</vs-button>
        </div>
      </template>
    </vs-dialog>

    <input @change="addExeFile()" ref="fileInput" class="hidden" type="file" />
  </div>
</template>

<script>
export default {
  name: "AddMedia",
  data: () => ({
    url: "",
    active: false,
    selectedType: "",
    selectedMediaSource: "youtube",
    types: ["image", "video"],
    mediaSources: ["youtube"],
    file: undefined,
  }),
  computed: {
    getIdFromUrl() {
      const url = this.url;
      const id = url.match(
        /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|&v(?:i)?=))([^#&?]*).*/
      );
      return id ? id[1] : null;
    },
    getThumbnail() {
      if (this.selectedType === "video") {
        return `https://img.youtube.com/vi/${this.getIdFromUrl}/0.jpg`;
      }
      return this.url;
    },
    validURl() {
      const url = this.url;
      const regex =
        /^(?:(?:https?):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(?:[1-9]|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(?:[1-9]|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(?:[1-9]|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))(?:\.(?:[1-9]|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])){3}|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/\S*)?$/;
      return regex.test(url);
    },
  },
  methods: {
    addMedia() {
      const types = this.$store.state.mediaTypes;

      const media = {
        file: this.file,
        type: types[this.selectedType],
      };

      if (this.selectedType === "video") {
        media.id = this.getIdFromUrl;
        media.url = this.url;
        media.source = this.selectedMediaSource;
      }

      if (this.file) {
        const fileTypeControl = this.imageTypeControl(this.file);
        if (!fileTypeControl) {
          return this.$vs.notification({
            position: "bottom-right",
            name: "Error",
            color: "danger",
            text: "File type not allowed",
          });
        }
      }

      this.$store.state.medias.push(media);

      this.active = false;
      this.url = "";
      this.selectedType = "";
      this.file = undefined;
    },
    addExeFile() {
      const vsInput = document.getElementById("vs-input--media-file-input");
      const el = this.$refs.fileInput;
      const file = el.files[0];

      if (vsInput && file) {
        vsInput.value = file.name;
        this.file = file;
      }
    },
    imageTypeControl(file) {
      if (!file.name.match(/\.(jpg|jpeg|png)$/)) {
        return false;
      }

      return true;
    },
    openFileModal() {
      this.$refs.fileInput.click();
    },
  },
};
</script>

<style scoped>

</style>
